import React from "react"
import Layout from "../component/Layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Lightbox from "../component/Lightbox"
import SEO from "../component/SEO"

const info = ({ data }) => {
  return (
    <Layout>
      <SEO title="Guest Information" />
      <section className="hero-content info">
        <div className="hero-title">
          <div className="inner-wrap">
            <h1>Information</h1>
            <h2>South Wales - Just outside Chepstow</h2>
          </div>
        </div>
      </section>

      <section className="info-content">
        <h4 className="guest-info-title">Guest Information</h4>
        <h3 className="floor-plan-btn">Click On Floor Plans</h3>

        <Lightbox images={data.plans.edges} />

        <div className="guest-info">
          <h3>Guest Access</h3>
          <p>
            We send written directions and key code information to all guests
            before their arrival.
          </p>
          <h3>Online Food Delivery</h3>
          <p>
            If the supermarket asks for a house number, Dorlands 1 or 2 is best!
          </p>
          <p>
            Dorlands House
            <br />
            Kilgwrrwg
            <br />
            Chepstow
            <br />
            NP16 6PT
          </p>
          <h3>Towels and Linen</h3>
          <p>
            All linen is provided and beds will be made. Towels are also
            provided along with bathroom essentials, soap, and toilet rolls.
            Please bring your own towels for the hot tub.
          </p>
          <h3>Early &amp; Late Checkout</h3>
          <p>
            Unfortunately early check in’s and late checks are not possible, our
            cleaning team are on a very tight schedule and with it being such a
            large house, it takes quite some time! Thank you for your
            understanding.
          </p>
          <h3>Getting Around</h3>
          <p>
            We are situated about 10/15 minutes from Chepstow town and we
            recommend having the use of a car during your stay. Chepstow town
            has a train and a coach station.
          </p>

          <h3>Taxis &amp; Minibuses</h3>
          <a
            className="transport-links"
            href="http://www.jaystaxis.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Jay’s Taxis 01291 626232
            <br />
            www.jaystaxis.org.uk
          </a>
          <br />
          <a
            className="transport-links"
            href="http://www.kandmtaxis.co.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            K&amp;M Taxis 01291 622906
            <br />
            www.kandmtaxis.co.uk
          </a>
          <br />
          <a
            className="transport-links"
            href="https://www.southwestminibuses.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Southwest Minibuses
            <br />
            www.southwestminibuses.com
          </a>
          <br />
          <a
            className="transport-links"
            href="http://www.bristol-minibuses.co.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bristol Minibuses
            <br />
            www.bristol-minibuses.co.uk
          </a>
        </div>
      </section>
    </Layout>
  )
}

info.propTypes = {
  data: PropTypes.object.isRequired,
}

export default info

export const planQuery = graphql`
  query floorPlanGallery {
    plans: allFile(filter: { sourceInstanceName: { eq: "plans" } }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
